import styled from "styled-components";

const H1 = styled.h1`
    margin: 0px auto;
    text-align: center;
    font-family: "Gotham-Medium";
    font-size: 23px;
    color: white;
    line-height: 150%;
`;

const P = styled.p`
  color: #D6ED16;
  font-family: "Gotham-Medium";
  text-align: center;
  margin: 5px auto;
  font-size: 28px;

`
export default function Headline() {
  return (
    <>
      <H1>
        RASCA Y GANA HASTA
      </H1>
      <P>
        100 GIROS GRATIS
      </P>
    </>
  );
}
