import React, { useState, useEffect } from "react";
import "./style.css";
import espadilhaIcon from "../images/icon.svg";
import popupImg from "../images/popup.png";
import logo from "../images/bet7logo.svg";
import Card from "../scratch/Card";
import styled from "styled-components";

interface ModalProps {
    token?: string | null;
    showModal: boolean;
    hiddeHeadline: boolean;

}

const Headline = styled.h1`
    margin: 15px 10px;
    text-align: center;
    font-family: "Gotham-Medium";
    font-size: 25px;
    color: white;
`

const CardComponent = styled.div`
    display: block;
    border: transparent;
  
`

const ModalComponent = styled.div`
    background-color: #232426;
    padding: 20px 10px;
    border-radius: 8px;

`

const Modal: React.FC<ModalProps> = ({ token, showModal, hiddeHeadline }) => {
    const [modal, setModal] = useState<boolean>(false);
    const [scratch, setScratch] = useState<boolean>(true)


    useEffect(() => {
        if (showModal) {
            setModal(true);
            setScratch(false);
        }
    }, [showModal]);

    return (
        <div id="overlay">
            <iframe
                src="https://bet7k.com/"
                style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    opacity: 1,
                    filter: "blur(1.5px)",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1000,
                }}
            ></iframe>
            <div id="modal" style={{ zIndex: 1001 }}>
                {modal && (
                    <ModalComponent>
                        <Headline>
                            <span style={{color:'#D6ED16', fontSize:'35px'}}>FELICIDADES</span> <br />
                            Gnaste hasta <br /> 100 giros gratis
                        </Headline>
                        <div className="modal-content">
                            <a href={`https://rodadab7k.com/?_lp=1&_token=${token}`}>
                                <div className="container">
                                    <img src={espadilhaIcon} alt="Espadilha Icon" />
                                    10 giros gratis | Depositar 70 Mxn
                                </div>
                            </a>
                            <a href={`https://rodadab7k.com/?_lp=1&_token=${token}`}>
                                <div className="container">
                                    <img src={espadilhaIcon} alt="Espadilha Icon" />
                                    30 giros gratis | Depositar 150 Mxn
                                </div>
                            </a>
                            <a href={`https://rodadab7k.com/?_lp=1&_token=${token}`}>
                                <div className="container">
                                    <img src={espadilhaIcon} alt="Espadilha Icon" />
                                    100 giros gratis | Depositar 300 Mxn
                                </div>
                            </a>

                            <div className="btn">
                                <a href={`https://rodadab7k.com/?_lp=1&_token=${token}`}>COMPLETA EL REGISTRO</a>
                                <button onClick={() => window.location.href = `https://rodadab7k.com/?_lp=1&_token=${token}`}>Tappar</button>
                            </div>
                        </div>
                        <div>
                            <img id="logo" src={logo} alt="CassinoPix Logo" />
                        </div>
                    </ModalComponent>
                )}
                {scratch && (
                    <CardComponent>
                        <Card />
                    </CardComponent>
                )}
            </div>
        </div>
    );
};

export default Modal;
